<template>
	<div class="dF fC f1 px-4 pb-4 hide-scrollbar"
		style="background:var(--light-bg-gray); overflow-y:scroll; z-index: 100;">
		<BHLoading :show="loading" />
		<div class="dF jSB aS">
			<h4 class="pb-2">{{ title }} Broadcast</h4>
			<!-- <div v-if="!broadcast.sentAt && broadcast.isScheduled" class="px-3 py-1"
				style="background-color: #FFFFFF; border: 1px solid #e8e8e8;">
				<div>We have <span style="font-weight: bold; color: red;">removed</span> broadcast <span
						style="font-weight: bold;">Schedule Time</span> to avoid sending it to users while you are editing.
					Please Reschedule it at your convenience.</div>
			</div> -->
		</div>
		<a-card>
			<a-steps :current="current" class="mb-5" :size="$mq === 'lg' ? 'default' : 'small'">
				<a-step v-for="item in steps" :key="item.title" :title="item.title">
					<span slot="description">{{ item.description }}</span>
				</a-step>
			</a-steps>

			<div v-if="current === 0">
				<a-form-model ref="broadcast" :model="broadcast" class="profile">
					<a-form-model-item label="Broadcast Name" prop="name" :rules="req('Please enter your broadcast name')">
						<a-input v-model="broadcast.name" placeholder="Add broadcast name" size="large"
							style="width: 70%;" />
					</a-form-model-item>

					<a-form-model-item label="Sent it from?" prop="sendingPhoneNumber"
						:rules="req('Please select the phone number')" style="width: 70%;">
						<a-select v-model="broadcast.sendingPhoneNumber" size="large">
							<a-select-option v-for="p in activePhoneNumbers" :key="p.id" :value="p.id">
								{{ p.phoneNumber }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-form-model>
			</div>

			<div v-else-if="current === 1">
				<a-form-model ref="broadcast" :model="broadcast" class="profile">
					<a-form-model-item label="Broadcast Message" prop="messagebody"
						:rules="req('Please enter your broadcast message')">
						<a-textarea v-model="broadcast.messagebody" placeholder="Add broadcast message" size="large"
							style="width: 70%;" :auto-size="{ minRows: 5, maxRows: 15 }" allow-clear />
					</a-form-model-item>
				</a-form-model>
				<a-alert class="mt-3" type="warning" show-icon style="width: fit-content;">
					<template slot="message">
						If the message body contains more than 160 GSM-7 characters (or 70 UCS-2 characters), the message is
						segmented and charged accordingly.
						Use <a href="https://twiliodeved.github.io/message-segment-calculator/" target="_blank" style="color: var(--primary)">this tool</a>
						to check how many segments the message will use.
					</template>
				</a-alert>
			</div>

			<div v-else-if="current === 2">
				<h5 class="mb-3">Who are you sending this Broadcast to?</h5>
				<a-radio-group v-if="resendingBroadcast" v-model="reMarketAudience" button-style="solid">
					<a-radio-button value="original_contacts">
						Original Contacts
					</a-radio-button>
				</a-radio-group>

				<a-form-model v-else ref="broadcast" :model="broadcast" class="profile">
					<a-form-model-item label="Send to All Contacts" prop="allContacts">
						<a-switch v-model="broadcast.allContacts" size="large" />
					</a-form-model-item>
					<a-form-model-item label="Send to" prop="isAgent">
						<a-radio-group v-model="broadcast.isAgent" :disabled="broadcast.allContacts" size="large">
							<a-radio-button :value="'all'">
								All
							</a-radio-button>
							<a-radio-button :value="true">
								Realtors
							</a-radio-button>
							<a-radio-button :value="false">
								Non Realtors
							</a-radio-button>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="Include Recipient by Tags" prop="tagI_any">
						<a-select :disabled="broadcast.allContacts" v-model="broadcast.tagI_any" mode="multiple"
							style="width: 70%" size="large" :filter-option="filterOption" show-search
							option-filter-prop="children">
							<a-select-option v-for="(option, optionI) in tags" :key="option + optionI"
								:value="option.value">
								{{ option.label }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="Exclude Recipient by Tags" prop="tagE_any">
						<div style="width: 100%; color: #9EA0A5;line-height: 1;margin-bottom: 15px;">Exclude anyone in this
							list from receiving this broadcast</div>
						<a-select :disabled="broadcast.allContacts" v-model="broadcast.tagE_any" mode="multiple"
							style="width: 70%" size="large" :filter-option="filterOption" show-search
							option-filter-prop="children">
							<a-select-option v-for="(option, optionI) in tags" :key="option + optionI"
								:value="option.value">
								{{ option.label }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-form-model>
				<a-alert v-if="contactsNotFound" class="mt-3" type="error" show-icon style="width: fit-content;">
					<template slot="message">
						No contacts were found. Please review and try again.
					</template>
				</a-alert>
			</div>

			<div v-else-if="current === 3">
				<a-form-model-item label="When would you like to send this broadcast?">
					<a-radio-group v-model="sendNow">
						<a-radio style="display: block; height: 30px; line-height: 30px;" :value="true">
							Send it now
						</a-radio>
						<a-radio style="display: block; height: 30px; line-height: 30px;" :value="false">
							Schedule a specific time and date
						</a-radio>
					</a-radio-group>
				</a-form-model-item>

				<div v-if="!sendNow">
					<a-form-model ref="broadcast" :model="broadcast" style="margin-left:1.8rem">
						<a-form-model-item label="Date" prop="scheduledDate">
							<a-date-picker v-model="broadcast.scheduledDate" @change="checkSchedule"
								placeholder="Select Date" />
						</a-form-model-item>
						<a-form-model-item label="Time" prop="scheduledTime">
							<a-time-picker v-model="broadcast.scheduledTime" @change="checkSchedule" use12-hours
								:minute-step="10" :default-value="moment('12:00', 'hh:mm A')" format="h:mm a" />
						</a-form-model-item>
						<a-form-model-item label="Time Zone">
							<div>
								<a-select v-model="selectedCountry" style="width: 70%; max-width:300px;"
									:options="countries" />
							</div>
							<div>
								<a-select @change="checkSchedule" v-model="broadcast.timezone"
									style="width: 70%;  max-width:300px;">
									<a-select-option v-for="(item, itemI) in timezones" :key="itemI" :value="item.value">{{
										item.label }}</a-select-option>
								</a-select>
							</div>
						</a-form-model-item>
					</a-form-model>
					<a-alert v-if="scheduleError" class="mt-3" type="error" show-icon style="width: fit-content;">
						<template slot="message">
							Error: The time you have chosen is invalid. Please try choosing a time in the future.
						</template>
					</a-alert>
				</div>
			</div>

			<div class="mt-5 dF aC jSB">
				<a-button v-if="current === 0" @click="cancelBroadcast" class="mr-3" size="large">CANCEL</a-button>
				<a-button v-else @click="previousStep" class="mr-3" size="large">BACK</a-button>
				<div style="width: 100%; text-align: right;">
					<a-button v-if="current > 1 && !resendingBroadcast" @click="submit(true)" type="primary" size="large"
						ghost class="mr-3">
						SAVE AS DRAFT </a-button>
					<a-button v-if="current !== 3" @click="nextStep" type="primary" size="large"> NEXT </a-button>
					<a-tooltip v-else :title="$p < 40 ? 'You do not have permission to send out Broadcasts' : ''">
						<a-button @click="showConfirm" type="primary" size="large"
							:disabled="$p < 40 || (!sendNow && Boolean(scheduleError))">SEND</a-button>
					</a-tooltip>
				</div>
			</div>
		</a-card>

		<a-modal :visible="showConfirmDialog" :footer="null" @cancel="showConfirmDialog = false">
			<div style="text-align:center">
				<div>
					<Rive v-if="!sendNow" src="schedule.riv" fit="cover" style="width:100%;" />
					<img v-else :src="sendNow ? sendImg : scheduleImg" style="max-width:270px; width:100%;" />
				</div>
				<div style="text-align:center; font-size:18px" class="mt-3">
					<span>
						{{ sendNow ? `You are about to send this broadcast now to` : `This scheduled broadcast will be sent
						to`}}<br />
					</span>

					<span style="display:block; font-size:24px; font-weight:bold; margin-top:10px;">{{ broadcastContacts
					}}</span>
					<span style="font-weight:bold;">Contacts </span>
					<template v-if="!sendNow && broadcast.scheduledString">
						<div style="font-size:18px;" class="mt-4">
							<div>Scheduled for </div>
							<div><b>{{ broadcast.scheduledString }}</b></div>
						</div>
					</template>

					<small class="send-disclaimer">Number shown might change based on valid phone number or non reputable
						phone number
						sources. Please read the following for more information.</small>

					<div class="dF jC mt-6">
						<a-button v-if="broadcastContacts === 0" class="ml-2" size="large"
							@click="showConfirmDialog = false" type="primary">CLOSE</a-button>
						<a-button v-else class="ml-2" size="large" @click="submit()" :disabled="broadcastContacts < 0"
							type="primary">SEND</a-button>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { formatDate } from 'bh-mod'
import BHLoading from 'bh-mod/components/common/Loading'
import moment from 'moment';
import { getTimezones, getCountries } from "@/assets/timezones";
import Rive from '@/components/common/Rive'

export default {
	components: {
		BHLoading,
		Rive
	},
	data() {
		return {
			loading: false,
			current: 0,
			sendImg: require('@/assets/sendEmail.svg'),
			scheduleImg: require('@/assets/schedule.svg'),
			contactsNotFound: false,
			selectedCountry: 'CA',
			isAgent: 'all',
			reMarketAudience: 'original_contacts',
			showConfirmDialog: false,
			broadcastContacts: -2,
			scheduleError: false,
			sendNow: true,
			previousSelectedTab: 'Sent',
			tags: [],
			steps: [
				{
					title: 'Step 1',
					description: 'Define Broadcast Info'
				},
				{
					title: 'Step 2',
					description: 'Broadcast Content'
				},
				{
					title: 'Step 3',
					description: 'Define Recipients'
				},
				{
					title: 'Finish',
					description: 'Deliver'
				},
			],
			broadcast: {
				name: '',
				sendingPhoneNumber: null,
				messagebody: '',
				timezone: '-04:00',
				allContacts: true,
				isAgent: 'all',
				tagI_any: [],
				tagE_any: [],
				scheduled: 0,
				scheduledString: '',
				isScheduled: false,
				scheduledTime: moment().endOf('hour').add(1, 'hour').add(1, 'minutes'),
				scheduledDate: moment()
			}
		}
	},

	computed: {
		title() {
			return this.editingBroadcast ? 'Edit' : this.resendingBroadcast ? 'Resend' : 'Add New';
		},

		instance() {
			return this.$store.state.instance
		},

		profileStatus() {
			return this.instance && this.instance.twilioApplicationToPersonData && this.instance.twilioApplicationToPersonData.twilioCustomerProfile && this.instance.twilioApplicationToPersonData.twilioCustomerProfile.status;
		},

		isProfileApproved() {
			return this.profileStatus === 'twilio-approved'
		},

		activePhoneNumbers() {
			return this.$store.state.sms.activePhoneNumbers;
		},

		resendingBroadcast() {
			return this.$route.path.includes('resend')
		},

		editingBroadcast() {
			return this.$route.path.includes('edit')
		},

		selectedTab() {
			return this.$store.state.sms.selectedTab;
		},

		countries() {
			return getCountries()
		},

		timezones() {
			return getTimezones(this.selectedCountry)
		},

		broadcasts() {
			return this.$store.state.sms.broadcasts
		}
	},

	watch: {
		selectedCountry() {
			this.broadcast.timezone = this.timezones[0].value
		},
		sendNow(val) {
			if (val) {
				this.broadcast.scheduledString = ''
			}
			else {
				this.checkSchedule()
			}
		},
	},

	methods: {
		moment, formatDate,

		req: (msg) => ({ required: true, message: msg }),

		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},

		cancelBroadcast() {
			let self = this
			return this.$confirm({
				title: `Are you sure you want to exit from ${this.editingBroadcast ? 'editing' : this.resendingBroadcast ? 'resending' : 'creating'} a broadcast?`,
				okText: 'EXIT',
				okType: 'danger',
				centered: true,
				onOk() {
					self.$router.push(`/`)
					self.$store.commit('SMS_TYPE', { value: self.previousSelectedTab })
				}
			})
		},

		previousStep() {
			this.current--;
			if (this.$refs.broadcast) {
				this.$refs.broadcast.clearValidate();
			}
		},

		async nextStep() {
			if (this.current === 2 && this.resendingBroadcast) {
				await this.fetchContactCounts()
				return
			}
			this.$refs.broadcast.validate(valid => {
				if (valid) {
					if (this.current === 2) {
						this.fetchContactCounts();
					} else {
						this.current++;
					}
				} else {
					return false;
				}
			});
		},

		async fetchContactCounts() {
			this.contactsNotFound = false
			this.$store.commit('LOAD', true)
			try {
				this.broadcastContacts = -1;
				const sendOBJ = JSON.parse(JSON.stringify(this.broadcast));
				if (this.resendingBroadcast) {
					sendOBJ.initialBroadcast = this.$route.params.id;
					sendOBJ.reMarketAudience = this.reMarketAudience;
				}

				if (this.broadcast.isAgent === 'all') {
					delete sendOBJ.isAgent;
				}

				const { data } = await this.$api.post(`/sms-broadcasts/:instance/contacts-count`, sendOBJ)
				this.broadcastContacts = +data || 0;
				if (this.broadcastContacts) {
					this.current++;
				} else {
					this.contactsNotFound = true
					this.$message.error('Sorry no contacts were found for your selection. Please review and try again.')
				}
			} catch (error) {
				this.$message.error('Error occurred while fetching contacts counts. Please review and try again.')
			}
			this.$store.commit('LOAD', false)
		},

		submit(saveAsDraft = false) {
			if (!this.sendNow) {
				if (this.scheduleError) return this.$message.error('Schedule time is invalid.')
				this.broadcast.isScheduled = true;
			}

			this.$store.commit('LOAD')

			let sendOBJ = { ...this.broadcast };

			sendOBJ.scheduled = this.sendNow ? 0 : new Date(sendOBJ.scheduled).getTime()

			if (this.resendingBroadcast) {
				sendOBJ.initialBroadcast = this.$route.params.id;
				sendOBJ.reMarketAudience = this.reMarketAudience;
			}

			if (this.broadcast.isAgent === 'all') {
				delete sendOBJ.isAgent;
			}

			if (!saveAsDraft && this.sendNow) {
				sendOBJ.send = true;
			}

			this.$api.post(`/sms-broadcasts/:instance`, sendOBJ).then(() => {
				this.$store.commit('LOAD', false)
				if (this.sendNow) {
					this.$store.commit('SET_PROP', { where: ['selectedTab'], what: 'Processing' })
				} else if (saveAsDraft) {
					this.$store.commit('SET_PROP', { where: ['selectedTab'], what: 'Drafts' })
				} else {
					this.$store.commit('SET_PROP', { where: ['selectedTab'], what: 'Scheduled' })
				}
				this.$store.commit('SMS_TYPE', { value: saveAsDraft ? 'Drafts' : this.sendNow ? 'Sent' : 'Scheduled' })
				this.$router.push('/')
			}).catch(err => {
				this.$store.commit('LOAD', false)
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while sending SMS broadcast. Please try again.`))
				}
			})
		},

		checkSchedule() {
			this.scheduleError = false
			try {
				let date = this.broadcast.scheduledDate.format('L');
				let time = this.broadcast.scheduledTime.format('HH:mm');
				let timezone = this.broadcast.timezone

				let scheduledString = `${date} ${time} ${timezone}`

				this.broadcast.scheduledString = scheduledString
				let scheduled = moment(scheduledString)

				if (scheduled < moment()) this.scheduleError = true
			} catch (err) {
				console.error('[ERROR] - CHECKING SCHEDULE ', err)
				this.scheduleError = true
			}
		},

		showConfirm() {
			if (this.sendNow) {
				this.broadcast.scheduledString = ''
			} else {
				this.checkSchedule()
			}

			if (!this.sendNow && Boolean(this.scheduleError)) return this.$message.error('Invalid Time. Please review your fields and try again')
			this.showConfirmDialog = true
		},
	},

	created() {
		if (!this.profileStatus || !this.isProfileApproved) {
			this.$router.push('/settings')
			this.$message.error('Your Profile is not yet created or approved. You can create broadcast once your profile is created and approved.');
		}
		this.previousSelectedTab = this.selectedTab
		this.$store.commit('SMS_TYPE', { value: `${this.title} Broadcast` })

		this.$api.get(`/tags/:instance?type=contacts`).then(({ data }) => {
			let tags = data.filter(x => x.type === "contacts").map(({ id, name }) => ({ label: name, value: id }))
			this.tags = JSON.parse(JSON.stringify(tags))
		})

		this.broadcast.name = `Broadcast created ${formatDate(Date.now())}`

		let id = this.$route.params.id || this.$route.query.copy

		if (id && this.broadcasts[id]) {
			this.broadcast = { ...this.broadcast, ...JSON.parse(JSON.stringify(this.broadcasts[id])) }

			if (this.broadcast.tagI_any && this.broadcast.tagI_any.length) {
				this.broadcast.tagI_any = this.broadcast.tagI_any.map(t => t.id || t)
			}
			if (this.broadcast.tagE_any && this.broadcast.tagE_any.length) {
				this.broadcast.tagE_any = this.broadcast.tagE_any.map(t => t.id || t)
			}

			this.broadcast.sendingPhoneNumber = this.broadcast.sendingPhoneNumber && this.broadcast.sendingPhoneNumber.id || null

			if (this.resendingBroadcast) {
				this.broadcast.name = 'Resend ' + this.broadcasts[id].name
			}

			if (this.$route.query.copy) {
				this.broadcast.name = this.broadcasts[id].name + ' -- Copy'
			}

			if (this.broadcast.scheduledString) {
				let scheduled = moment(this.broadcast.scheduledString)

				if (scheduled >= moment().add(3, 'minute')) {
					let s = this.broadcast.scheduledString.split(' ');
					let scheduledDate = s[0] && moment(s[0]) || moment();
					let scheduledTime = s[1] && moment(s[1], 'hh:mm A') || moment().endOf('hour').add(1, 'hour').add(1, 'minutes');
					let timezone = s[2] || this.timezones[0].value;

					this.broadcast.scheduledDate = scheduledDate;
					this.broadcast.scheduledTime = scheduledTime
					this.broadcast.timezone = timezone
					this.sendNow = false;
				}
			}
		}
	}
}
</script>

<style>
</style>
